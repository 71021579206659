/**
 * @description
 * like ***keyof*** but for values
 *
 * @example
 * ```ts
 * export const GENDER = {
 *  MALE: 'male',
 *  FEMALE: 'female',
 * } as const
 *
 * type Gender = ValueOf<typeof GENDER> // "male" | "female"
 * ```
 */
export type ValueOf<A> = Prettify<A[keyof A]>;

type Prettify<T> = {
  [K in keyof T]: T[K];
  // eslint-disable-next-line @typescript-eslint/ban-types
} & {};

/**
 * @description
 * useful to make autocomplte works in cases like { color: Colors | string }
 *
 * @example
 * ``ts
 * const Colors = {
 *  white: '#fff',
 *  black: '#000',
 * } as const
 *
 * type COLOR = Colors | string;
 * ```
 */
export type LiteralUnion<T extends U, U = string> = T | (U & Record<string, unknown>);

/**
 * @deprecated
 * This is a only a way to track the usage of any type with the goal of remove it in the future.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TS_FIXME = any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnknownFunction = (...args: any[]) => any;

/**
 * A TypeSafe version of Object Entries
 * Where would allow Enums to be the key, or template Literal Types
 */
export const objectEntries = <T extends object, K extends keyof T>(obj: T): [K, T[K]][] =>
  Object.entries(obj) as [K, T[K]][];

/**
 * A TypeSafe version of Object Keys
 * Where would allow Enums to be the key, or template Literal Types
 */
export const objectKeys = <T extends object, K extends keyof T>(obj: T): K[] => Object.keys(obj) as K[];

/**
 * Applies Partial to all the internal member recursively. Ideal for a merge tool that deep merges tree of objects
 * @example
 * ```ts
 * const getFakeState = (baseState: State, partialState: DeepPartial<State>) => _.merge(_.deepClone(baseState), partialState);
 *
 * const fakeState = getFakeState(state.getState(), {sessions: { currentVenue: { whitelabel: 'treatwell' }}});
 * state.getState = jest.fn(() => fakeState);
 * ```
 */
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;
